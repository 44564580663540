import React from 'react'
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as XLSX from 'xlsx';
//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import { FormControl, InputLabel, MenuItem, Select, Button } from '@mui/material';
import CMTextField from '../../components/atoms/CMTextField';

const HistorialOrdenController = () => {
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsexcel, setRowsexcel] = useState([]);
    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(10, 'days'));
    const [fechaFin, setFechaFin] = useState(moment().locale('es', MomentLocaleEs));
    const user = JSON.parse(window.localStorage.getItem("user"));
    const history = useHistory();
    const [rowsestado, setRowsestado] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedState, setSelectedState] = useState(0);  
    const [btndisabled, setBtndisabled] = useState(true);
    const [filtro,setFiltro] = useState('');
    const handleFiltro = (event) => {
      setFiltro(event.target.value);
    }
    const handleChange = (event) => {
      setSelectedState(event.target.value);
    };
    useEffect(() => {
        const dataList={
            "id_usuario": user.id_usuario,
            "from_date": fechaInicio,
            "to_date": fechaFin,
            "id_oc_estado":0,
            "filters":''
        }
        OrdenesService.getListControllerOC(dataList,setLoading,setRowsdata,user.token);
        OrdenesService.getListControllerOCHistorialExcel(dataList,setBtndisabled,setRowsexcel,user.token);
        OrdenesService.getOCListEstado(setRowsestado,user.token);

    }, []);
    const updateOrdenes= () =>{
        const dataList={
            "id_usuario": user.id_usuario,
            "from_date": fechaInicio,
            "to_date": fechaFin,
            "id_oc_estado":selectedState,
            "filters":filtro
        }
        setLoading(true);
        setBtndisabled(true);
        OrdenesService.getListControllerOC(dataList,setLoading,setRowsdata,user.token);
        OrdenesService.getListControllerOCHistorialExcel(dataList,setBtndisabled,setRowsexcel,user.token);
    }

    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };

    const exportToExcel = () => {
      const headers = [
        'ID OC',
        'OC SAP',
        'RUC',
        'Proveedor',
        'Nombre Solicitante',
        'Observaciones',
        'Motivo Solicitud',
        'Sub Total',
        'Impuesto',
        'importe total',
        'Nombre Estado',
        'Estado',
        'Destino',
        'Condición Pago',
        'Tipo',
        'Aprobador de Area',
        'Aprobador de Finanzas',
        'Fecha Creación',
        'Fecha Documento',
        'Fecha Contable',
        'Fecha Entrega',
        'Fecha Aprobacion Gerencia',
        'Fecha Aprobacion Finanzas',
        'Link Orden',
      ];
  
      const data = [
        headers,
        ...rowsexcel.map(item => [
            item.id_oc_cabecera,
            item.numero_documento_orden,
            item.ruc_proveedor,
            item.nombre_proveedor,
            item.nombre_solicitante,
            item.observaciones,
            item.motivo_solicitud,
            item.total_antes_descuento,
            item.impuesto,
            item.total_pago_vencido,
            item.ultimo_nombre_oc_estado,
            item.estado,
            item.destino,
            item.condic_pago,
            item.tipo,
            item.nombre_aprobador_gerencia,
            item.nombre_aprobador_finanzas,
            formatDate(item.created_at),
            formatDate(item.fecha_documento),
            formatDate(item.fecha_contable),
            formatDate(item.fecha_entrega),
            formatDate(item.fecha_aprob_gerencia),
            formatDate(item.fecha_aprob_finanzas),
            item.orden_pdf
        ])
    ];
   
      // const exportToExcel = () => {
      //   const headers = [
      //     'ID',
      //     'ID OC',
      //     'OC SAP',
      //     'RUC',
      //     'Proveedor',
      //     'Observaciones',
      //     'Motivo Solicitud',
      //     'Nombre Solicitante',
      //     'Tipo',
      //     'Estado',
      //     'Nombre Estado',
      //     'Destino',
      //     'Condición Pago',
      //     'Aprobador de Area',
      //     'Aprobador de Finanzas',
      //     'Fecha Creación',
      //     'Fecha Documento',
      //     'Fecha Contable',
      //     'Fecha Entrega',
      //     'Moneda',
      //     'Impuesto',
      //     'Sub Total',
      //     'importe total',
      //     'ID OC Detalle',
      //     'Fecha Entrega Detalle',
      //     'Descripción',
      //     'Código Artículo',
      //     'Cantidad Pedida',
      //     'Cantidad Entregada',
      //     'Cantidad Recibida',
      //     'Precio Unitario',
      //     'Porcentaje Descuento',
      //     'Total Artículo',
      //     'Unidad Medida'
      //   ];
    
      //   const data = [
      //     headers,
      //     ...rowsexcel.flatMap(item =>
      //       item.oc_detalles.map(detalle => [
      //         item.id,
      //         item.id_oc_cabecera,
      //         item.numero_documento_orden,
      //         item.ruc_proveedor,
      //         item.nombre_proveedor,
      //         item.observaciones,
      //         item.motivo_solicitud,
      //         item.nombre_solicitante,
      //         item.tipo,
      //         item.estado,
      //         item.ultimo_nombre_oc_estado,
      //         item.destino,
      //         item.condic_pago,
      //         item.nombre_aprobador_gerencia,
      //         item.nombre_aprobador_finanzas,
      //         formatDate(item.fecha_registro),
      //         formatDate(item.fecha_documento),
      //         formatDate(item.fecha_contable),
      //         formatDate(item.fecha_entrega),
      //         item.moneda,
      //         item.impuesto,
      //         item.total_antes_descuento,
      //         item.total_pago_vencido,
      //         detalle.id_oc_detalle,
      //         formatDate(detalle.fecha_entrega),
      //         detalle.descripcion,
      //         detalle.codigo_articulo,
      //         detalle.cantidad_pedida,
      //         detalle.cantidad_entregada,
      //         detalle.cantidad_recibida,
      //         detalle.precio_unitario,
      //         detalle.porcentaje_descuento,
      //         detalle.total_articulo,
      //         detalle.unidad_medida
      //       ])
      //     )
      //   ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ordenes');
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'OrdenesController.xlsx');
      };
      const formatDate = (dateString, moneda) => {
        const date = new Date(dateString);
      
        let symbol = '';
      
        if (moneda === 'S/') {
          symbol = 'S/';
        } else if (moneda === 'US$') {
          symbol = 'US$';
        }
      
        return symbol + date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
      };
    
      const saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(data, fileName);
        } else {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        }
      };
    const columns = [
      {
        field: "ver", headerName: "Ver", width:50, justifyContent: "center",
        renderCell: (cellValues) => {
          return (
            <CMIconButton onClick={() => {history.push(`/historialcontrolleroc/${cellValues.row.id_oc_cabecera}`); history.go(0);}}>
              <VisibilityIcon/>
            </CMIconButton>
          )
        }
      },
      {
        field: 'Id',
        headerName: 'Id',
        type: 'text',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.id_documento_borrador===null || params.row.id_documento_borrador===''  ? '000' +params.row.id_oc_cabecera : params.row.id_documento_borrador  ,
      },
      { field: 'numero_documento_orden', headerName: 'OC SAP', width: 100 },
      { field: 'ruc_proveedor', headerName: 'RUC', width: 100 },
        { field: 'nombre_proveedor', headerName: 'Nombre Proveedor', width: 350 },
        { field: 'nombre_solicitante', headerName: 'Nombre Solicitante', type: 'text', width: 150, editable: false },
        { field: 'observaciones', headerName: 'Observaciones', type: 'text', width: 450, editable: false },
        { field: 'motivo_solicitud', headerName: 'Motivo Solicitud', type: 'text', width: 450, editable: false },
        { field: 'total_antes_descuento', headerName: 'Sub Total', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_antes_descuento,params.row.moneda)  },
        { field: 'impuesto', headerName: 'Impuesto', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.impuesto,params.row.moneda) },
        { field: 'total_pago_vencido', headerName: 'importe total', type: 'number', width: 160, editable: false,  valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_pago_vencido,params.row.moneda) },
        { field: 'ultimo_nombre_oc_estado', headerName: 'Nombre Estado', type: 'text', width: 290, editable: false },
        { field: 'estado', headerName: 'Estado', type: 'text', width: 120, editable: false },
        { field: 'destino', headerName: 'Destino', type: 'text', width: 350, editable: false },
        { field: 'condic_pago', headerName: 'Condición Pago', type: 'text', width: 150, editable: false },
        { field: 'tipo', headerName: 'Tipo', type: 'text', width: 150 },
        { field: 'nombre_aprobador_gerencia', headerName: 'Aprobador de Area', type: 'text', width: 120, editable: false },
        { field: 'nombre_aprobador_finanzas', headerName: 'Aprobador de Finanzas', type: 'text', width: 150, editable: false },
        { field: 'created_at', headerName: 'Fecha Creación', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_documento', headerName: 'Fecha Documento', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          }, },
        { field: 'fecha_contable', headerName: 'Fecha Contable', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', type: 'text', width: 150, editable: false, valueGetter: (params) => {
            const fecha = params.value;
            return moment(fecha).format('DD-MM-YYYY');
          },},
          {
            field: 'fecha_aprob',
            headerName: 'Fecha Aprobacion Gerencia',
            type: 'text',
            width: 150,
            editable: false,
            valueGetter: (params) => {
              const tipo = params.row.tipo;
              const fecha = params.value;
              
              if (tipo === 'Servicio') {
                const fechaAprobGerencia = params.row.fecha_aprob_gerencia;
                return fechaAprobGerencia ? moment(fechaAprobGerencia).format('DD-MM-YYYY') : '';
              } else if (tipo === 'Articulo') {
                const fechaAprobFinanzas = params.row.fecha_aprob_finanzas;
                if (fechaAprobFinanzas === null) {
                  return '';
                }
                return moment(fechaAprobFinanzas).format('DD-MM-YYYY');
              }
              return '';
            },},
          { field: 'fecha_aprob_finanzas', headerName: 'Fecha Aprobacion Finanzas',  type: 'text', width: 150, editable: false,
          valueGetter: (params) => { const fecha = params.value;
            if (fecha === null) {
              return '';
            } return moment(fecha).format('DD-MM-YYYY');
          },},
        {
            field: 'orden_pdf',
            headerName: 'Descargar OC',
            width: 150,
             renderCell: (params) => {
               const pdfUrl = params.value;
               return (
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
                 <Icon>
                    <PictureAsPdf />
                 </Icon>
                 </a>
               );
             },
           },
        //   {
        //     field: 'factura_pdf',
        //     headerName: 'Descargar PDF',
        //     width: 150,
        //     renderCell: (params) => {
        //       const pdfUrl = params.value;
        //       return (
        //         <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
        //          <Icon>
        //             <PictureAsPdf />
        //         </Icon>
        //         </a>
        //       );
        //     },
        //   },
        //   {
        //     field: 'factura_xml',
        //     headerName: 'Descargar XML',
        //     width: 150,
        //     renderCell: (params) => {
        //       const pdfUrl = params.value;
        //       return (
        //         <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
        //          <Icon>
        //             <PictureAsPdf />
        //         </Icon>
        //         </a>
        //       );
        //     },
        //   },
        //   {
        //     field: 'factura_cdr',
        //     headerName: 'Descargar CDR',
        //     width: 150,
        //     renderCell: (params) => {
        //       const pdfUrl = params.value;
        //       return (
        //         <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
        //          <Icon>
        //             <PictureAsPdf />
        //         </Icon>
        //         </a>
        //       );
        //     },
        //   },
      ];
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Historial de Ordenes</b></CMLabel>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} justifyContent="center">
                <Grid item sm={12}>   
                    <hr></hr><br></br>
                    <Grid container item xs={12} sm={12} alignItems="center">
                        <Grid item xs={12} sm={2} md={1}>
                            <CMLabel fontSize='14px' color='black'>Fecha inicio creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={3.5} md={1.3}>
                            <CMDatePicker 
                                label="Desde"
                                value={fechaInicio}
                                onChange={(newDate) => setFechaInicio(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.5} md={0.2}>

                        </Grid>
                        <Grid item xs={12} sm={1.8} md={0.8}>
                            <CMLabel fontSize='14px' color='black'  sx={{marginLeft:'10px'}}>Fecha fin creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={3.5} md={1.3}>
                            <CMDatePicker 
                                label="Hasta"
                                value={fechaFin}
                                onChange={(newDate) => setFechaFin(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.5} md={0.2}>

                        </Grid>
                        <Grid item xs={12} sm={2} md={0.8}>
                            <CMLabel fontSize='14px' color='black'>Estados: </CMLabel>
                        </Grid>
                       <Grid item xs={12} sm={3.5} md={1.6}>
                        <Select
                          labelId="estado-select-label"
                          id="estado-select"
                          value={selectedState}
                          label="Selecciona un Estado"
                          onChange={handleChange}
                          fullWidth
                        >
                          <MenuItem value="" disabled>
                            Selecciona un estado
                          </MenuItem>
                          {rowsestado.map((estado) => (
                            <MenuItem key={estado.id_oc_estado} value={estado.id_oc_estado}>
                              {estado.nombre_oc_estado}
                            </MenuItem>
                          ))}
                        </Select>
                       </Grid>
                       <Grid container item sx={12} sm={0.5} md={0.2}>
                        </Grid>
                        <Grid container item sx={12} sm={1.8} md={0.5}>
                          <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black">Filtro</CMLabel>
                      </Grid>
                      <Grid container item sx={12} sm={3.5} md={2}>
                        <CMTextField
                            name="filtro"
                            label='fittro'
                            value={filtro}
                            onChange={(e)=>handleFiltro(e)}
                            fullWidth
                          />
                      </Grid>
                        <Grid item xs={12} sm={2} md={1} mx={{marginTop:'10px'}}>
                            <CMButton
                                label="Consultar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={updateOrdenes}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1} mx={{marginTop:'10px'}}>
                        <CMButton
                                label="Exportar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={exportToExcel}
                                disabled={btndisabled}
                            />
                        </Grid>
                     </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 500, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
    <DataGrid
      rows={rowsdata}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      loading={loading}  // Propiedad que indica si está cargando o no
      sx={{
        height: "100%",
        fontSize: 12,
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
    />
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default HistorialOrdenController