
import { BlobServiceClient } from '@azure/storage-blob';

import React, { useState,useEffect,useCallback} from "react";
import moment from 'moment';


import { Button, Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { Icon,Input,InputLabel } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useParams, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as OrdenesService from '../../services/OrdenesService';
import * as ProveedorService from '../../services/ProveedorService';


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CMTextField from "../../components/atoms/CMTextField";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CMIconButton from '../../components/atoms/CMIconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const MySwal = withReactContent(Swal)


const FacturasPendientesAdjuntarVer = () => {
  const history = useHistory();
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsgrid, setRowsgrid] = useState([]);
    const [comment,setComment] = useState([]);
    const [pdf,setPdf] = useState('');
    const [xml,setXml] = useState('');
    const [cdr,setCdr] = useState('');
    const [guia,setGuia] = useState('');
    const [ncpdf,setNcpdf] = useState('');
    const [ncxml,setNcxml] = useState('');
    const [ndpdf,setNdpdf] = useState('');
    const [ndxml,setNdxml] = useState('');
    
    const [boton,setBoton] = useState(false);
    const [enviar,setEnviar] = useState('');
    const user = JSON.parse(window.localStorage.getItem("user"));
    const useFakeMutation = () => {
      return React.useCallback(
        (user) =>
          new Promise((resolve, reject) =>
            setTimeout(() => {
              if (user.name?.trim() === '') {
                reject(new Error("Error al guardar el usuario: el nombre no puede estar vacÃ­o"));
              } else {
                resolve({ ...user, name: user.name?.toUpperCase() });
              }
            }, 200),
          ),
        [],
      );
    };
    const mutateRow = useFakeMutation();
    const [snackbar, setSnackbar] = useState(null);
    const [dataresponse,setDataresponse] = useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const handleProcessRowUpdateError = React.useCallback((error) => {
      setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const { ordenid } = useParams();
    useEffect(() => {
        OrdenesService.getNEID2(setRowsdata,ordenid,user.token);
    }, []);
    let idCounter = 0; // Inicializamos el contador

    useEffect(() => {
        if (rowsdata.ne_detalles) {
            const rowsWithId = rowsdata.ne_detalles.map(row => ({
                ...row,
                id: idCounter++
            }));
            setRowsgrid(rowsWithId);
        }
    }, [rowsdata]);
    
    useEffect(() => {
      if(rowsdata){
      setBoton(rowsdata.factura_cdr && rowsdata.factura_pdf && rowsdata.factura_xml);
      }
    }, [rowsdata])
    

    useEffect(() => {
      if(enviar==='Se registro correctamente' && rowsdata){
        // ProveedorService.getEnviarPDF(ordenid, user.token); enviar pdf pero se desactivo

      Swal.fire({
        title: 'Se registró correctamente!',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
            history.push(`/facturaspendienteadjuntarproveedor`);
            history.go(0);
        }
      });
    }
  }, [enviar]);

    //Agregando filtro de documenteo para servicios,compras y proveedores
    const ids_proveedores_exception = [738,750,784,795,797,798,799,803,806,868,875,888,909,912,919,949,960,1177,1178,1179,1180,1181,
    1182,1183,1184,1185,1220,1231,1320,1337,1360,2219];
    let cdr_name_button;
    let xml_name_button;
    if (ids_proveedores_exception.includes(rowsdata.id_proveedor)) {
      xml_name_button = 'Seleccionar Factura en XML';
      cdr_name_button = 'Seleccionar GUIA en PDF';
    } else {
      if (rowsdata.tipo === 'Servicio') {
        cdr_name_button = 'Seleccionar GUIA en PDF';
        xml_name_button = 'Seleccionar Factura en XML *';
      } else {
        cdr_name_button = 'Seleccionar GUIA en PDF*';
        xml_name_button = 'Seleccionar Factura en XML *';
      }
    }
    

    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };
     
   
   
      const columns = [
        { field: 'codigo_articulo', headerName: 'Codigo Árticulo', width: 120, align: 'center',headerAlign: 'center' },
        { field: 'nombre_articulo', headerName: 'Árticulo', width: 480,headerAlign: 'center' },
        { field: 'unidad_medida', headerName: 'Unidad Medida', width: 180, align: 'center',headerAlign: 'center' },
        { field: 'cantidad_pedida', headerName: 'Cantidad Pedida', width: 150, align: 'center',headerAlign: 'center' },
        { field: 'cantidad_entregada', headerName: 'Cantidad Entregada', type:'number', width: 150, align: 'center',headerAlign: 'center',editable: true},
        { field: 'cantidad_recibida', headerName: 'Cantidad Recibida', width: 150, align: 'center',headerAlign: 'center' },
        { 
            field: 'fecha_entrega', 
            headerName: 'Fecha Acuerdo', 
            type:'date',
            width: 140, 
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY') 
          },
          { 
            field: 'fecha_recepcion', 
            headerName: 'Fecha Entrega', 
            type: 'date',
            width: 140, 
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000',
            editable: true
          },
          { 
            field: 'fecha_termino', 
            headerName: 'Fecha Recibida', 
            type: 'date',
            width: 140, 
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
          },
          { field: 'precio_unitario', headerName: 'Precio Unitario', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda)  },
          { field: 'total_articulo', headerName: 'Total Árticulo', width: 150, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },

      ];
      const columns2 = [
        { field: 'descripcion', headerName: 'Descripción', width: 650, align: 'center',headerAlign: 'center'},
        { field: 'fecha_entrega', headerName: 'Fecha Entrega', width: 180, align: 'center',headerAlign: 'center',
        valueFormatter: (params) => params.value ? moment(params.value).format('DD/MM/YYYY') :'00/00/0000'
      },
        { field: 'nombre_cuenta_mayor', headerName: 'Nombre Cuenta Mayor', width: 300, align: 'center'},
        { field: 'numero_cuenta_mayor', headerName: 'Numero Cuenta Mayor', width: 180, align: 'center' },
        {
          field: 'tipo_igv',
          headerName: 'Tipo IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueGetter: (params) => {
            const valorSinIGV = params.row.include_igv==='EXONERADO' ?
             'EXONERADO' :
             params.row.include_igv==='IGV' ? 'IGV 18%': 'IGV 10%';
            return valorSinIGV;
          },
        },
        {
          field: 'valor_sin_igv',
          headerName: 'Precio sin IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
          valueGetter: (params) => {
            const totalServicio = params.row.total_servicio;
            const valorSinIGV = params.row.include_igv==='EXONERADO' ? totalServicio :
            params.row.include_igv==='IGV' ? totalServicio / (1.18) : totalServicio / (1.10);
            return valorSinIGV;
          },
        },
        {
          field: 'igv',
          headerName: 'IGV',
          width: 180,
          align: 'center',
          headerAlign: 'center',
          valueFormatter: (params) => formatCurrency(params.value, rowsdata.moneda),
          valueGetter: (params) => {
            const totalServicio = params.row.total_servicio;
            const valorSinIGV = params.row.include_igv==='EXONERADO' ?  0 :
            params.row.include_igv==='IGV' ? totalServicio - totalServicio / (1.18) : 
            totalServicio - totalServicio / (1.10);
            return valorSinIGV;
          },
        },
        { field: 'total_servicio', headerName: 'Precio', width: 180, align: 'center',headerAlign: 'center',valueFormatter: (params) => formatCurrency(params.value,rowsdata.moneda) },
  

  
      ]
      const processRowUpdate  = useCallback(
        async (newRow) => {
          // Make the HTTP request to save in the backend
          const response = await mutateRow(newRow);
          setDataresponse(response);
          
          setSnackbar({ children: 'Guardo exitosamente', severity: 'success' });
          return response;
  
        },
        [mutateRow],
        
      ); 
      useEffect(() => {
        if(dataresponse) {
            const {id_ne_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo} = dataresponse;
            if(!cantidad_recibida){
              alert('Debe ingresar la cantidad recibida');        
            }
            if(!fecha_recepcion){
              alert('Debe una fecha de entrega'); 
            }
            
            const oldDevices = [...rowsgrid];
            const rowDeviceIndex = oldDevices.findIndex((dev) => dev.id_ne_detalle === id_ne_detalle);
            oldDevices[rowDeviceIndex] = {
              ...oldDevices[rowDeviceIndex],
              id_ne_detalle,codigo_articulo,unidad_medida,cantidad_pedida,cantidad_entregada,cantidad_recibida,fecha_entrega,fecha_recepcion,fecha_termino,precio_unitario,total_articulo
            };
            setRowsgrid(oldDevices);
        }
      }, [dataresponse])


      const handleComentarioChange = e => {
        const {name, value} = e.target;
        setComment(value)
      }

      const handlePDF = (e) =>{
        const file = e.target.files[0];
        
        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setPdf(file);
        } else {
          setSnackbar({ children: 'Debe subir la factura con extensión .pdf', severity: 'error' });

        }
      }

      const handleXML = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setXml(file);
        } else {
          setSnackbar({ children: 'Debe subir el XML con extensión .xml', severity: 'error' });
        }
      }

      const handleCDR = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setCdr(file);
        } else {
          setSnackbar({ children: 'Debe subir el CDR con extensión .xml', severity: 'error' });
        }
      }
      const handleGUIA = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setGuia(file);
        } else {
          setSnackbar({ children: 'Debe subir la guia con extensión .pdf', severity: 'error' });
        }
      }
      const handleNCPDF = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setNcpdf(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Credito con extensión .pdf', severity: 'error' });
        }
      }
      const handleNCXML = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setNcxml(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Credito con extensión .xml', severity: 'error' });
        }
      }
      const handleNDPDF = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.pdf') || file.name.endsWith('.PDF')) {
          setNdpdf(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Debito con extensión .pdf', severity: 'error' });
        }
      }
      const handleNDXML = (e) =>{
        const file = e.target.files[0];

        if (file.name.endsWith('.xml') || file.name.endsWith('.XML')) {
          setNdxml(file);
        } else {
          setSnackbar({ children: 'Debe subir la Nota de Debito con extensión .xml', severity: 'error' });
        }
      }
      const uploadFilesToBlobStorage = async () => {
        if(ids_proveedores_exception.includes(rowsdata.id_proveedor)){
          if(!pdf){
            setSnackbar({ children: 'Falta subir la factura con extensión .pdf', severity: 'error' });
            return
          }
          // if(!cdr){
          //   setSnackbar({ children: 'Falta subir el CDR con extensión .xml', severity: 'error' });
          //   return
          // }
          // if(!xml){
          //   setSnackbar({ children: 'Falta subir el XML con extensión .xml', severity: 'error' });
          //   return
          // }
          // if(!guia){
          //   setSnackbar({ children: 'Falta subir la GUIA con extensión .pdf', severity: 'error' });
          //   return
          // }
        }
      else{
        if(rowsdata.tipo==='Servicio'){
          if(!pdf){
            setSnackbar({ children: 'Falta subir la factura con extensión .pdf', severity: 'error' });
            return
          }
          // if(!cdr){
          //   setSnackbar({ children: 'Falta subir el CDR con extensión .xml', severity: 'error' });
          //   return
          // }
          if(!xml){
            setSnackbar({ children: 'Falta subir el XML con extensión .xml', severity: 'error' });
            return
          }
          // if(!guia){
          //   setSnackbar({ children: 'Falta subir la GUIA con extensión .pdf', severity: 'error' });
          //   return
          // }
        }
        else{
          if(!pdf){
            setSnackbar({ children: 'Falta subir la factura con extensión .pdf', severity: 'error' });
            return
          }
          // if(!cdr){
          //   setSnackbar({ children: 'Falta subir el CDR con extensión .xml', severity: 'error' });
          //   return
          // }
          if(!xml){
            setSnackbar({ children: 'Falta subir el XML con extensión .xml', severity: 'error' });
            return
          }
          if(!guia){
            setSnackbar({ children: 'Falta subir la GUIA con extensión .pdf', severity: 'error' });
            return
          }  
        }
      }
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString('es-ES', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).replace(/[\s/:,]/g, '');
        
      
        const files = [
          {"base64": pdf, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".pdf"},
          {"base64": xml, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".XML"},
          {"base64": cdr, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".XML"},
          {"base64": guia, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".pdf"},
          {"base64": ncpdf, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".pdf"},
          {"base64": ncxml, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".xml"},
          {"base64": ndpdf, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".pdf"},
          {"base64": ndxml, "nombre_del_archivo": rowsdata.numero_documento_orden + formattedDate + ".xml"}

        ];
      
        // Recorrer cada archivo y obtener su contenido en base64
        const base64Array = await Promise.all(
          files.map((file) => {
            return new Promise((resolve, reject) => {
              if (file.base64) {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64String = reader.result.split(',')[1];
                  resolve({ base64: base64String, nombre: file.nombre });
                };
                reader.onerror = (error) => {
                  reject(error);
                };
                reader.readAsDataURL(file.base64);
              } else {
                resolve(null); // Si el archivo no está definido, agregar un valor nulo al array
              }
            });
          })
        );
        Swal.fire({
          title: 'Registro de documentos PDF,XML,CDR y GUIA',
          text: "¿Esta conforme de subir documentos?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
          }).then((result) => {
          async function handleConfirmedResult() {
          if (result.isConfirmed) {
              const datosDocumentos = {
                id_oc_cabecera: rowsdata.id_oc_cabecera,
                id_ne_cabecera:rowsdata.id_ne_cabecera,
                id_proveedor: rowsdata.id_proveedor,
                nombre_factura_pdf: `TT${rowsdata.numero_documento}.pdf`,
                factura_pdf:base64Array[0].base64,
                nombre_factura_xml:`TT${rowsdata.numero_documento}.xml`,
                factura_xml:base64Array[1] ? base64Array[1].base64: undefined,
                nombre_factura_cdr:`TT${rowsdata.numero_documento}.cdr`,
                factura_cdr:base64Array[2] ? base64Array[2].base64: undefined,
                nombre_factura_guia:`TT${rowsdata.numero_documento}.pdf`,
                facturaGuia:base64Array[3] ? base64Array[3].base64 : undefined,
                nombre_credito_pdf:`NC${rowsdata.numero_documento}.pdf`,
                credito_pdf:base64Array[4] ? base64Array[4].base64 : undefined,
                nombre_credito_xml:`NC${rowsdata.numero_documento}.xml`,
                credito_xml:base64Array[5] ? base64Array[5].base64 : undefined,
                nombre_debito_pdf:`ND${rowsdata.numero_documento}.pdf`,
                debito_pdf:base64Array[6] ? base64Array[6].base64 : undefined,
                nombre_debito_xml:`ND${rowsdata.numero_documento}.xml`,
                debito_xml:base64Array[7] ? base64Array[7].base64 : undefined,
              }
              // console.log(datosDocumentos);
              setBoton(true);
              try {
                await ProveedorService.crearDocumentosOC(datosDocumentos, setBoton, user.token);
                setEnviar('Se registro correctamente');
              } catch (error) {
                console.error('Error al crear los documentos:', error);
                setEnviar('Hubo un error al registrar');
              }
           }
         }
         handleConfirmedResult();
        })
        // console.log(base64Array);
      };
      const handleClickRegresar = () => {
        history.push(`/facturaspendienteadjuntarproveedor`);
        history.go(0);
      }
      useEffect(() => {
        if(rowsdata.id_proveedor && user){
             if(user.id_usuario != rowsdata.id_proveedor){
              history.push('/inicio');
              history.go();
            }
        }
      }, [rowsdata])
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={12}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Numero de Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'} #{rowsdata.numero_documento_orden}</b></CMLabel>
                </Grid>
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Numero de Nota de entrega {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'} #{rowsdata.numero_documento}</b></CMLabel>
                </Grid>
                <Grid item sm={6} container justifyContent="flex-end" alignItems="center" >
                  <Grid container xs={2} justifyContent="flex-end" alignItems="center" >
                        <Grid item justifyContent="flex-end" alignItems="center" >
                          <CMIconButton disableRipple={true} size="large" sx={{color: "primary.main", display: "block"}} onClick={handleClickRegresar}>
                            <ArrowBackIcon/>
                            <Typography sx={{lineHeight: 0}}>Regresar</Typography>
                          </CMIconButton>
                        </Grid>
                  </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Proveedor</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='16px' color='black'><b>Nombre :</b> {rowsdata.nombre_proveedor}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Solicitante</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Nombre :</b> {rowsdata.nombre_solicitante}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Destino :</b> {rowsdata.destino}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Detalle Orden de {rowsdata.tipo==='Articulo' ? 'Compra' : 'Servicio'}</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Estado :</b> {rowsdata.estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>OC Estado :</b> {rowsdata.ultimo_nombre_oc_estado}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Observación :</b> {rowsdata.observaciones}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Condición de Pago :</b> {rowsdata.condic_pago}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Fecha Contable :</b> {moment(rowsdata.fecha_contable).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Fecha Entrega :</b> {moment(rowsdata.fecha_entrega).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>Fecha Registro :</b> {moment(rowsdata.fecha_registro).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                    <Grid item sm={12}>
                        <CMLabel fontSize='14px' color='black'><b>OC Actualización :</b> {moment(rowsdata.ultima_fecha_modificacion).format("DD-MM-YYYY")}</CMLabel>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                   
                </Grid>
                <Grid item sm={5.5}>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Importe</b></CMLabel>
                    <hr></hr>
                    <Grid item sm={6} container justifyContent="space-between" alignItems="center" >
                      <Grid item sm={12} container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Subtotal :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'> {rowsdata.total_antes_descuento ? formatCurrency(rowsdata.total_antes_descuento,rowsdata.moneda) : ''}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                          <Grid>
                          <CMLabel fontSize='16px' color='black'><b>Impuesto :</b></CMLabel>
                          </Grid>
                          <Grid>
                          <CMLabel fontSize='16px' color='black'>  { rowsdata.impuesto ? formatCurrency(rowsdata.impuesto,rowsdata.moneda) : formatCurrency(0,rowsdata.moneda)}</CMLabel>
                          </Grid>
                      </Grid>
                      <Grid item sm={12}  container justifyContent="space-between">
                        <Grid>
                        <CMLabel fontSize='16px' color='black'><b>Total :</b></CMLabel>
                        </Grid>
                        <Grid>
                        <CMLabel fontSize='16px' color='black'> {rowsdata.total_pago_vencido ? formatCurrency(rowsdata.total_pago_vencido,rowsdata.moneda) : ''}</CMLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    <CMLabel sx={{ margin: 2 }} fontSize='20px' color="black"><b>Adjuntar Archivos</b></CMLabel>
                    <hr></hr>
                    <Grid item container sm={12}>
                    <CMLabel sx={{ margin: 2 }} fontSize='14px' color="black"> Por favor, suba los archivos de la factura en .pdf y el archivo XML que son documentos obligatorios relacionados con su solicitud</CMLabel>

                      <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!pdf ? 'Seleccionar Factura en PDF *' : 'Se subio correctamente PDF'}
                            <input
                              type="file"
                              onChange={handlePDF}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.factura_pdf &&
                          <Grid item sm={4}>
                          <a href={rowsdata.factura_pdf} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          PDF
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                          <Grid item sm={4}>
                          <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                            {!xml ? xml_name_button : 'Se subio correctamente XML'}
                            <input
                              type="file"
                              onChange={handleXML}
                              hidden
                            />
                          </Button>
                          </Grid>
                          {rowsdata.factura_xml &&
                            <Grid item sm={4}>
                            <a href={rowsdata.factura_xml} target="_blank" rel="noopener noreferrer" download>
                            <Icon>
                              <CloudDownloadIcon/>
                            </Icon>
                            XML
                            </a>
                            </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                          <Grid item sm={4}>
                            <Button
                              variant="contained"
                              component="label"
                              sx={{marginTop:"6px",marginBottom:"6px"}}
                            >
                            {!cdr ? 'Seleccionar CDR en XML' : 'Se subio correctamente CDR'}
                              <input
                                type="file"
                                onChange={handleCDR}
                                hidden
                              />
                            </Button>
                          </Grid>
                          {rowsdata.factura_cdr &&
                            <Grid item sm={4}>
                            <a href={rowsdata.factura_cdr} target="_blank" rel="noopener noreferrer" download>
                            <Icon>
                              <CloudDownloadIcon/>
                            </Icon>
                            CDR
                            </a>
                            </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!guia ? cdr_name_button : 'Se subio correctamente la GUIA'}
                            <input
                              type="file"
                              onChange={handleGUIA}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.factura_guia &&
                          <Grid item sm={4}>
                          <a href={rowsdata.factura_guia} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          GUIA
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ncpdf ? 'Seleccionar Nota de Crédito en PDF' : 'Se subio correctamente la NC'}
                            <input
                              type="file"
                              onChange={handleNCPDF}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.credito_pdf &&
                          <Grid item sm={4}>
                          <a href={rowsdata.credito_pdf} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          CREDITOPDF
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ncxml ? 'Seleccionar Nota de Crédito en XML' : 'Se subio correctamente la NC'}
                            <input
                              type="file"
                              onChange={handleNCXML}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.credito_xml &&
                          <Grid item sm={4}>
                          <a href={rowsdata.credito_xml} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          CREDITOXML
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ndpdf ? 'Seleccionar Nota de Débito en PDF' : 'Se subio correctamente la ND'}
                            <input
                              type="file"
                              onChange={handleNDPDF}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.debito_pdf &&
                          <Grid item sm={4}>
                          <a href={rowsdata.debito_pdf} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          DEBITOPDF
                          </a>
                          </Grid>
                          }
                    </Grid>
                    <Grid item container sm={12}>
                        <Grid item sm={4}>
                        <Button
                            variant="contained"
                            component="label"
                            sx={{marginTop:"6px",marginBottom:"6px"}}
                          >
                           {!ndxml ? 'Seleccionar Nota de Débito en XML' : 'Se subio correctamente la ND'}
                            <input
                              type="file"
                              onChange={handleNDXML}
                              hidden
                            />
                          </Button>
                        </Grid>
                          {rowsdata.debito_xml &&
                          <Grid item sm={4}>
                          <a href={rowsdata.debito_xml} target="_blank" rel="noopener noreferrer" download>
                          <Icon>
                            <CloudDownloadIcon/>
                          </Icon>
                          DEBITOXML
                          </a>
                          </Grid>
                          }
                    </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid >
  {/* {rowsdata.tipo==='Articulo' ?
    <DataGrid
      rows={rowsgrid}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      experimentalFeatures={{ newEditingApi: true }}
    />
     :  <DataGrid
     rows={rowsgrid}
     columns={columns2}
     pageSize={15}
     rowsPerPageOptions={[15]}
     sx={{
       height: "100%",
       "& .MuiDataGrid-window": {
         overflowX: 'scroll',
         "&::-webkit-scrollbar": {
           width: "0.4em",
         },
         "&::-webkit-scrollbar-track": {
           background: "#f1f1f1",
         },
         "&::-webkit-scrollbar-thumb": {
           backgroundColor: "#888",
         },
         "&::-webkit-scrollbar-thumb:hover": {
           background: "#555",
         },
       },
     }}
     pagination
     rowHeight={50}
     processRowUpdate={processRowUpdate}
     onProcessRowUpdateError={handleProcessRowUpdateError}
     experimentalFeatures={{ newEditingApi: true }}
   />} */}
     {!!snackbar && (
                    <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                    >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
  </Grid>
  <Grid item xs={12} sm={12} container>
    <Grid item xs={12} sm={6} container alignItems="center">
        {/* <Grid item xs={6} sm={3}>
					<CMLabel fontSize='16px'color='black'>Comentario: </CMLabel>
				</Grid>
				<Grid item xs={6} sm={6}>
					<CMTextField
						name="comment"
						label='Comentario'
						value={comment}
						onChange={handleComentarioChange}
						fullWidth
					/>
				</Grid> */}
    </Grid>
    <Grid item xs={12} sm={0.5} container>

    </Grid>
    
    <Grid item xs={12} sm={3} container>
    <CMButton
      label="Registrar Documentos"
      variant="contained"
      sx={{ marginTop: '10px' }}
      onClick={uploadFilesToBlobStorage}
      disabled={boton}
    />
    </Grid>
    
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default FacturasPendientesAdjuntarVer