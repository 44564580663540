import React from 'react'
import moment from 'moment';


import { Grid ,Typography} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
  } from '@mui/x-data-grid';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as XLSX from 'xlsx';
//Components
import CMLabel from '../../components/atoms/CMLabel';
import CMDatePicker from '../../components/molecules/CMDatePicker';
import CMButton from '../../components/atoms/CMButton';
import CMIconButton from '../../components/atoms/CMIconButton';

//Constants
import {MomentLocaleEs} from '../../constants/MomentLocaleEs';
//Services
import * as SolicitanteService from '../../services/SolicitanteService';

const OrdenesRechazadas = () => {
    const [rowsdata, setRowsdata] = useState([]);
    const [rowsexcel, setRowsexcel] = useState([]);
    const [sortModel, setSortModel] = useState([]); // Add sortModel state

    const [fechaInicio, setFechaInicio] = useState(moment().locale('es', MomentLocaleEs).subtract(10, 'days'));
    const [fechaFin, setFechaFin] = useState(moment().locale('es', MomentLocaleEs));
    const user = JSON.parse(window.localStorage.getItem("user"));
    const history = useHistory();
    useEffect(() => {
        const dataList={
            "id_usuario": user.id_usuario,
            "from_date": fechaInicio,
            "to_date": fechaFin
        }
        SolicitanteService.getListRechazadas(dataList,setRowsdata,user.token);
        SolicitanteService.getListRechazadasExcel(dataList,setRowsexcel,user.token);

    }, []);
    const updateOrdenes= () =>{
        const dataList={
            "id_usuario": user.id_usuario,
            "from_date": fechaInicio,
            "to_date": fechaFin
        }
        SolicitanteService.getListRechazadas(dataList,setRowsdata,user.token);
        SolicitanteService.getListRechazadasExcel(dataList,setRowsexcel,user.token);
    }


 
    const formatCurrency = (value, moneda) => {
      let currency = 'PEN';
    
      if (moneda === 'S/') {
          currency = 'PEN';
        }else  if (moneda === 'US$') {
        currency = 'USD';
      }
    
      return value.toLocaleString('es-PE', {
        style: 'currency',
        currency: currency,
      });
    };

   
      const exportToExcel = () => {
        const headers = [
          'ID',
          'Numero Documento',
          'Tipo',
          'Observaciones',
          'Estado',
          'Nombre Estado',
          'Destino',
          'Condición Pago',
          'Fecha Creación',
          'Fecha Documento',
          'Fecha Contable',
          'Fecha Entrega',
          'Solicitante',
          'Aprobador Gerencia',
          'Aprobador Finanzas',
          'Moneda',
          'Impuesto',
          'Sub Total',
          'importe total',
          'ID OC Detalle',
          'Fecha Entrega Detalle',
          'Descripción',
          'Código Artículo',
          'Cantidad Pedida',
          'Cantidad Entregada',
          'Cantidad Recibida',
          'Precio Unitario',
          'Porcentaje Descuento',
          'Total Artículo',
          'Unidad Medida'
        ];
    
        const data = [
          headers,
          ...rowsexcel.flatMap(item =>
            item.oc_detalles.map(detalle => [
              item.id,
              item.numero_documento_orden,
              item.tipo,
              item.observaciones,
              item.estado,
              item.ultimo_nombre_oc_estado,
              item.destino,
              item.condic_pago,
              formatDate(item.fecha_registro),
              formatDate(item.fecha_documento),
              formatDate(item.fecha_contable),
              formatDate(item.fecha_entrega),
              item.nombre_solicitante,
              item.nombre_aprobador_gerencia,
              item.nombre_aprobador_finanzas,
              item.moneda,
              item.impuesto,
              item.total_antes_descuento,
              item.total_pago_vencido,
              detalle.id_oc_detalle,
              formatDate(detalle.fecha_entrega),
              detalle.descripcion,
              detalle.codigo_articulo,
              detalle.cantidad_pedida,
              detalle.cantidad_entregada,
              detalle.cantidad_recibida,
              detalle.precio_unitario,
              detalle.porcentaje_descuento,
              detalle.total_articulo,
              detalle.unidad_medida
            ])
          )
        ];
    
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Conformidad ordenes');
    
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcelFile(excelBuffer, 'conformidad-ordenes.xlsx');
      };
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
      };
    
      const saveAsExcelFile = (buffer, fileName) => {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(data, fileName);
        } else {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
        }
      };
    const columns = [
      {
        field: "ver", headerName: "Ver", width:50, justifyContent: "center",
        renderCell: (cellValues) => {
          return (
            <CMIconButton onClick={() => {history.push(`/ordenesrechazadas/${cellValues.row.id_oc_cabecera}`); history.go(0);}}>
              <VisibilityIcon/>
            </CMIconButton>
          )
        }
      },
      {
        field: 'Id',
        headerName: 'Id',
        type: 'text',
        width: 150,
        editable: false,
        valueGetter: (params) => params.row.id_documento_borrador===null || params.row.id_documento_borrador===''  ? '000' +params.row.id_oc_cabecera : params.row.id_documento_borrador  ,
      },

      { field: 'numero_documento_orden', headerName: 'OC SAP', width: 150 },
      { field: 'tipo', headerName: 'Tipo', type: 'text', width: 150 },
      { field: 'nombre_proveedor', headerName: 'Nombre Proveedor', type: 'text', width: 300 },
      { field: 'observaciones', headerName: 'Observaciones', type: 'text', width: 700, editable: false },
      { field: 'estado', headerName: 'Estado', type: 'text', width: 120, editable: false },
      { field: 'ultimo_nombre_oc_estado', headerName: 'Nombre Estado', type: 'text', width: 290, editable: false },
      { field: 'destino', headerName: 'Destino', type: 'text', width: 350, editable: false },
      { field: 'condic_pago', headerName: 'Condición Pago', type: 'text', width: 150, editable: false },
      { field: 'fecha_registro', headerName: 'Fecha Creación', type: 'text', width: 150, editable: false, valueGetter: (params) => {
          const fecha = params.value;
          return moment(fecha).format('DD-MM-YYYY');
        }, },
      { field: 'fecha_documento', headerName: 'Fecha Documento', type: 'text', width: 150, editable: false, valueGetter: (params) => {
          const fecha = params.value;
          return moment(fecha).format('DD-MM-YYYY');
        }, },
      { field: 'fecha_contable', headerName: 'Fecha Contable', type: 'text', width: 150, editable: false, valueGetter: (params) => {
          const fecha = params.value;
          return moment(fecha).format('DD-MM-YYYY');
        },},
      { field: 'fecha_entrega', headerName: 'Fecha Entrega', type: 'text', width: 150, editable: false, valueGetter: (params) => {
          const fecha = params.value;
          return moment(fecha).format('DD-MM-YYYY');
        },},
        { field: 'ultima_fecha_modificacion', headerName: 'OC Fecha Modificación', type: 'text', width: 150, editable: false ,valueGetter: (params) => {
          const fecha = params.value;
          return moment(fecha).format('DD-MM-YYYY');
        },},
      { field: 'nombre_solicitante', headerName: 'Solicitante', type: 'text', width: 200, editable: false },
      { field: 'nombre_aprobador_gerencia', headerName: 'Aprobador Gerencia', type: 'text', width: 150, editable: false },
      { field: 'nombre_aprobador_finanzas', headerName: 'Aprobador Finanzas', type: 'text', width: 150, editable: false },
      { field: 'moneda', headerName: 'Moneda', type: 'text', width: 150, editable: false },
      { field: 'impuesto', headerName: 'Impuesto', type: 'number', width: 160, editable: false,   valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.impuesto,params.row.moneda) },
      { field: 'total_antes_descuento', headerName: 'Sub Total', type: 'number', width: 160, editable: false, valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_antes_descuento,params.row.moneda)},
      { field: 'total_pago_vencido', headerName: 'importe total', type: 'number', width: 160, editable: false, valueFormatter: (params) => params.value ? params.value : '',valueGetter: (params) => formatCurrency(params.row.total_pago_vencido,params.row.moneda) },
      {
          field: 'orden_pdf',
          headerName: 'Descargar OC',
          width: 150,
          renderCell: (params) => {
            const pdfUrl = params.value;
            return (
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer" download>
               <Icon>
                  <PictureAsPdf />
              </Icon>
              </a>
            );
          },
        },
      ];
 return (
    <Grid width={'100%'} m="auto">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2} sx={{py:1}}>
        <Grid container item xs={12} sm={12} >
                <Grid item sm={6}>
                    <CMLabel sx={{ margin: 2 }} fontSize='24px' color="black"><b>Ordenes Rechazadas de Servicio/Mercaderia</b></CMLabel>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} justifyContent="center">
                <Grid item sm={12}>
                    <hr></hr>
                    <Grid item sm={12}>
                    </Grid>
                    <Grid container item xs={12} sm={12} alignItems="center">
                        <Grid item xs={12} sm={1.5}>
                            <CMLabel fontSize='14px' color='black'>Fecha inicio creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMDatePicker 
                                label="Desde"
                                value={fechaInicio}
                                onChange={(newDate) => setFechaInicio(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={0.5}>
                        </Grid>
                        <Grid item xs={12} sm={1.5}>
                            <CMLabel fontSize='14px' color='black'  sx={{marginLeft:'10px'}}>Fecha fin creación: </CMLabel>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <CMDatePicker 
                                label="Hasta"
                                value={fechaFin}
                                onChange={(newDate) => setFechaFin(newDate)}
                                readOnly={false}
                                fullWidth
                                disabled={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <CMButton
                                label="Consultar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={updateOrdenes}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                        <CMButton
                                label="Exportar"
                                variant="contained"
                                sx={{marginLeft:'10px'}}
                                onClick={exportToExcel}
                            />
                        </Grid>
                     </Grid>
                </Grid>
        </Grid>
        <Grid container item xs={12} sm={12}>
  <Grid style={{ height: 500, width: '100%', overflowX: 'auto' }} sx={{ py: 0 }}>
    <DataGrid
      rows={rowsdata}
      columns={columns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      sx={{
        height: "100%",
        "& .MuiDataGrid-window": {
          overflowX: 'scroll',
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        },
      }}
      pagination
      rowHeight={50}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
  </Grid>
</Grid>
    </Grid>
    </Grid>
 )
}

export default OrdenesRechazadas